$title-color: #98918a;
$background-color: #f8f8f8;
$border-color: #eae5e1;

.display-none {
  display: none;
}

.divider {
  height: 1px;
  border-bottom: 1px solid $border-color;

  &--error {
    border-bottom: 1px solid $colour-red;
  }
}

.divider-error {
  height: 1px;
  border-bottom: 1px solid $colour-red;
}

.filters {
  &__overlay {
    margin-top: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: $background-color;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    background-color: $background-color !important;
  }

  &__leased-spacing {
    margin-top: 60px;
  }

  &__options-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__secondary-text {
    color: $title-color;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  &__secondary-text__number {
    color: $title-color;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
    margin-top: 20px;
  }

  &__section {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid $border-color;
    border-top: 0.5px solid $border-color;

    &_ad-number {
      padding: 33px 15px 15px;
    }
  }

  &__last-block {
    margin-bottom: calc(85px + env(safe-area-inset-bottom));
  }

  &__ad-delete-icon {
    transform: translate(0, -10px);
  }

  &__fields-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }

  &__leased_type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid $colour-grey-wild-sand;
    height: 38px;
    font-size: 15px;
    color: #2f1f19;
    margin: 15px 0;

    &-active {
      background-color: $colour-cocoa-brown;
      border-color: $colour-cocoa-brown;
      color: $colour-white;

      &_blue {
        background-color: $colour-light-blue;
        border-color: $colour-neon-blue;
        color: $colour-neon-blue;
      }
    }
  }

  &__search_by_id {
    color: #2f1f19;
    background-color: #fff;
    font-size: 15px;
    width: 100%;
    display: flex;
    outline: none;
    border: 0;
    align-items: center;
    justify-content: flex-start;
  }

  &__link {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    color: $colour-neon-blue;
    text-decoration: none;
    background-color: transparent;
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 15px;
  }

  &__option {
    display: flex;
    width: 50%;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:first-child .filters__leased_type {
      border-radius: 14px 0 0 14px;
    }

    &:last-child .filters__leased_type {
      border-radius: 0 14px 14px 0;
    }
  }

  &__last-floor {
    font-size: 15px;
    color: #2f1f19;
  }

  &__floor {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    padding-right: 28px;
    outline: none;

    @include arrow(10px, $colour-heathered-grey, 2px) {
      margin-left: auto;
      transform: rotate(45deg);
      margin-right: -18px;
    }
  }

  &--no-border-top {
    border-top: 0;
  }
}
