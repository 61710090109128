.new-search__locations {
  &_placeholder {
    font-size: 15px;
    color: $colour-heathered-grey;
  }

  &_names {
    @include ellipsis;
    color: $colour-cocoa-brown;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
